export default {
  message: {
    welcome: 'Hi，welcome ',
    login: 'login',
    logout: 'logout',
    register: 'register',
    help: 'help',
    shoppingCart: 'cart',
    notification: 'notice',
    userfeedback: 'feedback',
    merchantCenter: 'shop center',
    merchantSettled: 'shop settled',
    serviceHotline: 'tel',
    languages: 'languages',
    registerAccount: 'register',
    userLogin: 'login',
    userCenter: 'User Center'
  },
  category: {
    all: 'allCategory',
    home: 'home',
    sourceCodeHall: 'sourceCode hall',
    demandCenter: 'demand center',
    serviceCenter: 'service center',
    ITPluralism: 'pluralism center',
    serviceProviderCenter: 'service provider',
    documentCenter: 'document center',
    designCenter: 'design center',
    help: 'inSite consultation'
  }
}
